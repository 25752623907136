// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-line-title-article {
  cursor: pointer;
}

.single-line-title-article__image {
  margin-bottom: 18px;
  width: 100%;
  height: 273px;
  object-fit: cover;
  overflow: hidden;
}

.single-line-title-article__category {
  margin-bottom: 5px;
}

.single-line-title-article__title {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.single-line-title-article__text {
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.single-line-title-article__source {
  font-size: 16px;
  line-height: 19px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SingleLineTitleArticle/SingleLineTitleArticle.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".single-line-title-article {\n  cursor: pointer;\n}\n\n.single-line-title-article__image {\n  margin-bottom: 18px;\n  width: 100%;\n  height: 273px;\n  object-fit: cover;\n  overflow: hidden;\n}\n\n.single-line-title-article__category {\n  margin-bottom: 5px;\n}\n\n.single-line-title-article__title {\n  margin-bottom: 14px;\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 28px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.single-line-title-article__text {\n  margin-bottom: 15px;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n\n.single-line-title-article__source {\n  font-size: 16px;\n  line-height: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
