// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.related-small-article {
  margin-bottom: 35px;
  cursor: pointer;
  display: block;
}

.related-small-article:last-child {
  margin-bottom: 0;
}

.related-small-article__container {
  display: grid;
  grid-template-columns: repeat(4, var(--grid-column-width));
  gap: var(--grid-gap);
  align-items: center;
}

.related-small-article__image {
  grid-column: span 2;
  width: 100%;
  height: 220px;
  object-fit: cover;
  overflow: hidden;
}

.related-small-article__content {
  grid-column: span 2;
}

.related-small-article__category {
  margin-bottom: 8px;
}

.related-small-article__title {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.related-small-article__source {
  font-size: 16px;
  line-height: 19px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/RelatedSmallArticle/RelatedSmallArticle.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,0DAA0D;EAC1D,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".related-small-article {\n  margin-bottom: 35px;\n  cursor: pointer;\n  display: block;\n}\n\n.related-small-article:last-child {\n  margin-bottom: 0;\n}\n\n.related-small-article__container {\n  display: grid;\n  grid-template-columns: repeat(4, var(--grid-column-width));\n  gap: var(--grid-gap);\n  align-items: center;\n}\n\n.related-small-article__image {\n  grid-column: span 2;\n  width: 100%;\n  height: 220px;\n  object-fit: cover;\n  overflow: hidden;\n}\n\n.related-small-article__content {\n  grid-column: span 2;\n}\n\n.related-small-article__category {\n  margin-bottom: 8px;\n}\n\n.related-small-article__title {\n  margin-bottom: 15px;\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 24px;\n  display: -webkit-box;\n  -webkit-line-clamp: 4;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n\n.related-small-article__source {\n  font-size: 16px;\n  line-height: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
