// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation__logo {
  display: block;
}

.navigation__logo-image {
  width: 100%;
}

.navigation__list {
  list-style: none;
  display: flex;
  align-items: baseline;
}

.navigation__item {
  margin-right: 60px;
}

.navigation__item:last-child {
  margin-right: 0;
}

.navigation__link {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--link-color);
}

.navigation__link--active,
.navigation__link:hover {
  color: var(--color-accent);
}

.navigation--footer {
  align-items: center;

  --link-color: var(--color-white);
}

.navigation--footer .navigation__logo {
  grid-column: span 2;
  padding-right: 56px;
}

.navigation--footer .navigation__list {
  grid-column: 3/-1;
}

.navigation__header {
  --link-color: var(--color-primary);
}

.navigation--header .navigation__logo {
  grid-column: span 4;
  padding-right: 32px;
}

.navigation--header .navigation__list {
  grid-column: 5/-1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Navigation/Navigation.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;;EAEE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;;EAEnB,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".navigation__logo {\n  display: block;\n}\n\n.navigation__logo-image {\n  width: 100%;\n}\n\n.navigation__list {\n  list-style: none;\n  display: flex;\n  align-items: baseline;\n}\n\n.navigation__item {\n  margin-right: 60px;\n}\n\n.navigation__item:last-child {\n  margin-right: 0;\n}\n\n.navigation__link {\n  font-size: 24px;\n  font-weight: 900;\n  text-transform: uppercase;\n  cursor: pointer;\n  color: var(--link-color);\n}\n\n.navigation__link--active,\n.navigation__link:hover {\n  color: var(--color-accent);\n}\n\n.navigation--footer {\n  align-items: center;\n\n  --link-color: var(--color-white);\n}\n\n.navigation--footer .navigation__logo {\n  grid-column: span 2;\n  padding-right: 56px;\n}\n\n.navigation--footer .navigation__list {\n  grid-column: 3/-1;\n}\n\n.navigation__header {\n  --link-color: var(--color-primary);\n}\n\n.navigation--header .navigation__logo {\n  grid-column: span 4;\n  padding-right: 32px;\n}\n\n.navigation--header .navigation__list {\n  grid-column: 5/-1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
