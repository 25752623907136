"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmallArticle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./SmallArticle.css");
var utils_1 = require("../../utils");
var react_router_dom_1 = require("react-router-dom");
var SmallArticle = function (_a) {
    var title = _a.title, source = _a.source, date = _a.date, articleId = _a.articleId;
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/article/".concat(articleId), className: 'small-article', children: (0, jsx_runtime_1.jsxs)("article", { className: "small-article__container", children: [(0, jsx_runtime_1.jsx)("h2", { className: "small-article__title", children: title }), (0, jsx_runtime_1.jsx)("span", { className: "article-date", children: source }), (0, jsx_runtime_1.jsx)("span", { className: "article-source", children: (0, utils_1.beautifyDate)(date) })] }) }));
};
exports.SmallArticle = SmallArticle;
