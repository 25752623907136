"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainArticle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./MainArticle.css");
var react_router_dom_1 = require("react-router-dom");
var MainArticle = function (_a) {
    var title = _a.title, image = _a.image, category = _a.category, description = _a.description, source = _a.source, articleId = _a.articleId;
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/article/".concat(articleId), className: "main-article", children: (0, jsx_runtime_1.jsxs)("article", { className: "main-article__container", children: [(0, jsx_runtime_1.jsx)("div", { className: "main-article__image-container", children: (0, jsx_runtime_1.jsx)("img", { className: "article-img main-article__img", src: image, alt: "\u0424\u043E\u0442\u043E \u043D\u043E\u0432\u043E\u0441\u0442\u0438" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "main-article__content", children: [(0, jsx_runtime_1.jsx)("span", { className: "article-category", children: category }), (0, jsx_runtime_1.jsx)("h2", { className: "main-article__title", children: title }), (0, jsx_runtime_1.jsx)("p", { className: "main-article__text", children: description }), (0, jsx_runtime_1.jsx)("span", { className: "article-source main-article__caption", children: source })] })] }) }));
};
exports.MainArticle = MainArticle;
