"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.beautifyDate = exports.categoryNames = exports.categoryIds = void 0;
exports.categoryIds = {
    main: 0,
    sport: 2,
    technologies: 1,
    karpov: 6,
    fashion: 3,
};
exports.categoryNames = {
    main: 'Главная',
    fashion: 'Мода',
    technologies: 'Технологии',
    sport: 'Спорт',
    karpov: 'Karpov',
};
var beautifyDate = function (date) {
    return new Date(date).toLocaleDateString('ru-RU', {
        month: 'long',
        day: 'numeric',
    });
};
exports.beautifyDate = beautifyDate;
