"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticleItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
require("./Article.css");
var RelatedSmallArticle_1 = require("../RelatedSmallArticle/RelatedSmallArticle");
var SingleLineTitleArticle_1 = require("../SingleLineTitleArticle/SingleLineTitleArticle");
var utils_1 = require("../../utils");
var react_router_dom_1 = require("react-router-dom");
var ArticleItem = function () {
    var _a = (0, react_1.useState)(null), article = _a[0], setArticle = _a[1];
    var _b = (0, react_1.useState)(null), relatedArticles = _b[0], setRelatedArticles = _b[1];
    var _c = (0, react_1.useState)([]), categories = _c[0], setCategories = _c[1];
    var _d = (0, react_1.useState)([]), sources = _d[0], setSources = _d[1];
    var id = (0, react_router_dom_1.useParams)().id;
    (0, react_1.useEffect)(function () {
        // fetch('https://frontend.karpovcourses.net/api/v2/news/full/' + articleId)
        fetch('https://frontend.karpovcourses.net/api/v2/news/full/' + id)
            .then(function (response) { return response.json(); })
            .then(function (response) {
            setArticle(response);
        });
        Promise.all([
            fetch("https://frontend.karpovcourses.net/api/v2/news/related/".concat(id, "?count=9")).then(function (response) { return response.json(); }),
            fetch("https://frontend.karpovcourses.net/api/v2/categories").then(function (response) { return response.json(); }),
            fetch("https://frontend.karpovcourses.net/api/v2/sources").then(function (response) { return response.json(); }),
        ]).then(function (responses) {
            var relatedArticles = responses[0];
            var categories = responses[1];
            var sources = responses[2];
            setRelatedArticles(relatedArticles.items);
            setCategories(categories);
            setSources(sources);
        });
    }, [id]);
    if (article === null || relatedArticles === null) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("section", { className: "article-page", children: [(0, jsx_runtime_1.jsxs)("article", { className: "article", children: [article.image.length ? ((0, jsx_runtime_1.jsx)("section", { className: "article__hero", style: { backgroundImage: "url(".concat(article.image, ")") }, children: (0, jsx_runtime_1.jsxs)("div", { className: "container article__hero-content", children: [(0, jsx_runtime_1.jsx)("div", { className: "grid", children: (0, jsx_runtime_1.jsx)("h1", { className: "article__hero-title", children: article.title }) }), (0, jsx_runtime_1.jsxs)("div", { className: "grid", children: [(0, jsx_runtime_1.jsx)("span", { className: "article-category article__category", children: article.category.name }), (0, jsx_runtime_1.jsx)("span", { className: "article-date article__date", children: (0, utils_1.beautifyDate)(article.date) })] })] }) })) : null, (0, jsx_runtime_1.jsxs)("div", { className: "grid container article__main", children: [(0, jsx_runtime_1.jsxs)("div", { className: "article__content", children: [!article.image.length ? ((0, jsx_runtime_1.jsxs)("div", { className: "article__title-container", children: [(0, jsx_runtime_1.jsx)("h1", { className: "article__title", children: article.title }), (0, jsx_runtime_1.jsxs)("div", { className: "grid", children: [(0, jsx_runtime_1.jsx)("span", { className: "article-category article__category", children: article.category.name }), (0, jsx_runtime_1.jsx)("span", { className: "article-date article__date", children: (0, utils_1.beautifyDate)(article.date) })] })] })) : null, (0, jsx_runtime_1.jsx)("p", { children: article.text })] }), (0, jsx_runtime_1.jsx)("div", { className: "article__small-column", children: relatedArticles.slice(3, 9).map(function (item) {
                                    var category = categories.find(function (_a) {
                                        var id = _a.id;
                                        return item.category_id === id;
                                    });
                                    var source = sources.find(function (_a) {
                                        var id = _a.id;
                                        return item.source_id === id;
                                    });
                                    return ((0, jsx_runtime_1.jsx)(RelatedSmallArticle_1.RelatedSmallArticle, { title: item.title, image: item.image, category: (category === null || category === void 0 ? void 0 : category.name) || '', source: (source === null || source === void 0 ? void 0 : source.name) || '', id: item.id }, item.id));
                                }) })] })] }), (0, jsx_runtime_1.jsx)("section", { className: "article-page__related-articles", children: (0, jsx_runtime_1.jsxs)("div", { className: "container", children: [(0, jsx_runtime_1.jsx)("h2", { className: "article-page__related-articles-title", children: "\u0427\u0438\u0442\u0430\u0439\u0442\u0435 \u0442\u0430\u043A\u0436\u0435:" }), (0, jsx_runtime_1.jsx)("div", { className: "grid article-page__related-articles-list", children: relatedArticles.slice(0, 3).map(function (item) {
                                var category = categories.find(function (_a) {
                                    var id = _a.id;
                                    return item.category_id === id;
                                });
                                var source = sources.find(function (_a) {
                                    var id = _a.id;
                                    return item.source_id === id;
                                });
                                return ((0, jsx_runtime_1.jsx)(SingleLineTitleArticle_1.SingleLineTitleArticle, { title: item.title, image: item.image, text: item.description, category: (category === null || category === void 0 ? void 0 : category.name) || '', source: (source === null || source === void 0 ? void 0 : source.name) || '', id: item.id }, item.id));
                            }) })] }) })] }));
};
exports.ArticleItem = ArticleItem;
