// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.articles {
  margin-bottom: 135px;
}

.articles__big-column {
  grid-column: span 9;
}

.articles__small-column {
  grid-column: 10/-1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Articles/Articles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".articles {\n  margin-bottom: 135px;\n}\n\n.articles__big-column {\n  grid-column: span 9;\n}\n\n.articles__small-column {\n  grid-column: 10/-1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
