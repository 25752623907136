// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-article {
  margin-bottom: 35px;
  padding-left: 11px;
  cursor: pointer;
  border-left: 9px solid var(--color-primary);
  display: block;
}

.small-article:last-child {
  margin-bottom: 0;
}

.small-article__title {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-primary);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SmallArticle/SmallArticle.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,2CAA2C;EAC3C,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,2BAA2B;EAC3B,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":[".small-article {\n  margin-bottom: 35px;\n  padding-left: 11px;\n  cursor: pointer;\n  border-left: 9px solid var(--color-primary);\n  display: block;\n}\n\n.small-article:last-child {\n  margin-bottom: 0;\n}\n\n.small-article__title {\n  margin-bottom: 14px;\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 24px;\n  color: var(--color-primary);\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
