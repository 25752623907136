// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding-top: 38px;
  padding-bottom: 150px;
}

.header--article-page {
  padding-bottom: 100px;
}

.footer {
  padding-top: 22px;
  padding-bottom: 56px;
  background-color: var(--color-primary);
  min-width: var(--container-width);
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.footer__text {
  font-size: 24px;
  line-height: 28px;
  color: var(--color-white);
}

.footer__text--gray {
  font-size: 24px;
  line-height: 28px;
  color: var(--color-grey);
}

.footer__link {
  color: var(--color-accent);
  font-weight: 700;
  cursor: pointer;
}

.footer__link:hover {
  opacity: 0.8;
}

.footer__navigation {
  margin-bottom: 47px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/App/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,sCAAsC;EACtC,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".header {\n  padding-top: 38px;\n  padding-bottom: 150px;\n}\n\n.header--article-page {\n  padding-bottom: 100px;\n}\n\n.footer {\n  padding-top: 22px;\n  padding-bottom: 56px;\n  background-color: var(--color-primary);\n  min-width: var(--container-width);\n}\n\n.footer__bottom {\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n}\n\n.footer__text {\n  font-size: 24px;\n  line-height: 28px;\n  color: var(--color-white);\n}\n\n.footer__text--gray {\n  font-size: 24px;\n  line-height: 28px;\n  color: var(--color-grey);\n}\n\n.footer__link {\n  color: var(--color-accent);\n  font-weight: 700;\n  cursor: pointer;\n}\n\n.footer__link:hover {\n  opacity: 0.8;\n}\n\n.footer__navigation {\n  margin-bottom: 47px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
