"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedSmallArticle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./RelatedSmallArticle.css");
var react_router_dom_1 = require("react-router-dom");
var RelatedSmallArticle = function (_a) {
    var image = _a.image, source = _a.source, title = _a.title, category = _a.category, id = _a.id;
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/article/".concat(id), className: "related-small-article", children: (0, jsx_runtime_1.jsxs)("article", { className: "related-small-article__container", children: [(0, jsx_runtime_1.jsx)("img", { className: "related-small-article__image", src: image }), (0, jsx_runtime_1.jsxs)("div", { className: "related-small-article__content", children: [(0, jsx_runtime_1.jsx)("span", { className: "article-category related-small-article__category", children: category }), (0, jsx_runtime_1.jsx)("h2", { className: "related-small-article__title", children: title }), (0, jsx_runtime_1.jsx)("span", { className: "article-source related-small-article__source", children: source })] })] }) }));
};
exports.RelatedSmallArticle = RelatedSmallArticle;
