"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleLineTitleArticle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./SingleLineTitleArticle.css");
var react_router_dom_1 = require("react-router-dom");
var SingleLineTitleArticle = function (_a) {
    var title = _a.title, source = _a.source, image = _a.image, category = _a.category, text = _a.text, id = _a.id;
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/article/".concat(id), children: (0, jsx_runtime_1.jsxs)("article", { className: "single-line-title-article", children: [(0, jsx_runtime_1.jsx)("img", { className: "single-line-title-article__image", src: image }), (0, jsx_runtime_1.jsx)("span", { className: "article-category single-line-title-article__category", children: category }), (0, jsx_runtime_1.jsx)("h2", { className: "single-line-title-article__title", children: title }), (0, jsx_runtime_1.jsx)("p", { className: "single-line-title-article__text", children: text }), (0, jsx_runtime_1.jsx)("span", { className: "article-source single-line-title-article__source", children: source })] }) }));
};
exports.SingleLineTitleArticle = SingleLineTitleArticle;
