// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-primary: #262a2f;
  --color-accent: #f53;
  --color-grey: #868686;
  --color-white: #fff;
  --container-width: 1440px;
  --grid-column-width: 98px;
  --grid-gap: 20px;
}

* {
  all: unset;
  display: revert;
}

body {
  font-family: Rubik, sans-serif;
  background-color: #fff;
  font-size: 22px;
  font-weight: 400;
  color: var(--color-primary);
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: var(--container-width);
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, var(--grid-column-width));
  gap: var(--grid-gap);
}

.article-date,
.article-source {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.article-source {
  color: var(--color-grey);
}

.article-date {
  color: var(--color-accent);
}

.article-date + .article-source::before {
  content: " · ";
}

.article-category {
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-accent);
  text-transform: uppercase;
  font-weight: 700;
}

.article-img {
  background: #ccc;
  display: block;
  width: 100%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/common.css"],"names":[],"mappings":"AAEA;EACE,wBAAwB;EACxB,oBAAoB;EACpB,qBAAqB;EACrB,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,8BAA8B;EAC9B,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,oBAAoB;AACtB;;AAEA;;EAEE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,WAAW;EACX,iBAAiB;AACnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700;900&display=swap\");\n\n:root {\n  --color-primary: #262a2f;\n  --color-accent: #f53;\n  --color-grey: #868686;\n  --color-white: #fff;\n  --container-width: 1440px;\n  --grid-column-width: 98px;\n  --grid-gap: 20px;\n}\n\n* {\n  all: unset;\n  display: revert;\n}\n\nbody {\n  font-family: Rubik, sans-serif;\n  background-color: #fff;\n  font-size: 22px;\n  font-weight: 400;\n  color: var(--color-primary);\n}\n\n.container {\n  margin: 0 auto;\n  padding: 0 20px;\n  max-width: var(--container-width);\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: repeat(12, var(--grid-column-width));\n  gap: var(--grid-gap);\n}\n\n.article-date,\n.article-source {\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 24px;\n  text-transform: uppercase;\n}\n\n.article-source {\n  color: var(--color-grey);\n}\n\n.article-date {\n  color: var(--color-accent);\n}\n\n.article-date + .article-source::before {\n  content: \" · \";\n}\n\n.article-category {\n  display: block;\n  font-size: 14px;\n  line-height: 17px;\n  color: var(--color-accent);\n  text-transform: uppercase;\n  font-weight: 700;\n}\n\n.article-img {\n  background: #ccc;\n  display: block;\n  width: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
